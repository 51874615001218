import { Stack, useToast } from "@chakra-ui/react"
import React, { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import PasswordForm from "~/components/account/PasswordForm"
import ErrorAlert from "~/components/ErrorAlert"
import { usePrinticularApi } from "~/hooks/usePrinticularApi"
import AccountLayout from "~/templates/layout/account/AccountLayout"

interface Props {}

type FormData = {
  password: string
  confirmation: string
}

const ChangePassword: React.FC<Props> = () => {
  const api = usePrinticularApi()
  const toast = useToast()
  const { t } = useTranslation()
  const [error, setError] = useState<any>()
  const [isSaving, setIsSaving] = useState(false)

  const methods = useForm()
  const { handleSubmit } = methods

  const onSubmit = async (data: FormData) => {
    setIsSaving(true)

    try {
      const { password } = data
      await api.changePassword({
        password,
      })

      toast({
        title: t("pages.account.change-password.ToastTitle"),
        description: t("pages.account.change-password.ToastDescription"),
        status: "success",
        duration: 2000,
        isClosable: true,
      })
    } catch (error) {
      setError(error)
    }

    setIsSaving(false)
  }

  return (
    <AccountLayout
      title={t("pages.account.change-password.Title")}
      description={t("pages.account.change-password.Description")}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={5} spacing={5}>
            {error && <ErrorAlert data={error} />}
            <PasswordForm isLoading={isSaving} />
          </Stack>
        </form>
      </FormProvider>
    </AccountLayout>
  )
}

export default ChangePassword
